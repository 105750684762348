<template>
  <div class="staff-induction-detail-page">
    <!-- 基础信息部分 -->
    <base-headline title="员工基本档案" class="headline no-top no-bottom" />
    <el-divider class="base-divider" />
    <div class="staff-basic-info">
      <div class="staff-head-img">
        <img style="width: 100%;height: 100%;" v-if="$zwxBase.verifyIsNotBlank(msStaffInfoDetailForm.personnelPhoto)" :src="$store.state.file + msStaffInfoDetailForm.personnelPhoto" />
        <div class="ic-avatar-empty" v-if="$zwxBase.verifyIsBlank(msStaffInfoDetailForm.personnelPhoto)"></div>
      </div>
      <div class="staff-info-column">
        <div class="staff-info-row">
          <div class="staff-info-label" style="min-width:42px !important">姓名：</div>
          <div class="staff-info-value">{{ msStaffInfoDetailForm.staffName }}</div>
          <i class="colorful chis-icon-colorful-male" style="margin-left: 4px" v-if="msStaffInfoDetailForm.sexContent === '男'"></i>
          <i class="colorful chis-icon-colorful-female" style="margin-left: 4px" v-if="msStaffInfoDetailForm.sexContent === '女'"></i>
          <div style="width: 34px;height: 18px;background: #e75d55;font-size: 12px;font-weight: 500;color: #ffffff;line-height: 18px;letter-spacing: 0.6px;text-align: center;border-radius: 3px;margin-left: 15px" v-if="msStaffInfoDetailForm.type === 1">接害</div>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">出生日期：</span>
          <span class="staff-info-value">
            {{ dateStrFormatter(msStaffInfoDetailForm.birthDate) }}
            <span v-if="$zwxBase.verifyIsNotBlank(msStaffInfoDetailForm.age)">({{ msStaffInfoDetailForm.age }}岁)</span>
          </span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">手机号码：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.phone || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label" style="min-width:42px !important">籍贯：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.nativePlaceContent || '--' }}</span>
        </div>
      </div>
      <div class="staff-info-split-line"></div>
      <div class="staff-info-column">
        <div class="staff-info-row">
          <span class="staff-info-label">证件类型：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.idTypeContent || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">证件号码：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.cardNo || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">婚姻状态：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.ifMarryContent || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">文化程度：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.cultureContent || '--' }}</span>
        </div>
      </div>
      <div class="staff-info-split-line"></div>
      <div class="staff-info-column">
        <div class="staff-info-row">
          <span class="staff-info-label" style="min-width:42px !important">工号：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.jobNumber || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">是否外委：</span>
          <span class="staff-info-value">{{ outWorkerFormatter(msStaffInfoDetailForm.outWorker) }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label" style="min-width: 86px;">岗位 (工种) ：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.jobName || '--' }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label" style="min-width: 86px;">部门 (车间) ：</span>
          <span class="staff-info-value">{{ msStaffInfoDetailForm.workplaceName || '--' }}</span>
        </div>
      </div>
      <div class="staff-info-split-line"></div>
      <div class="staff-info-column">
        <div class="staff-info-row">
          <span class="staff-info-label">初次参加工作时间：</span>
          <span class="staff-info-value">{{ dateStrFormatter(msStaffInfoDetailForm.firstWorkDate) }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">到本单位工作时间：</span>
          <span class="staff-info-value">{{ dateStrFormatter(msStaffInfoDetailForm.hiredate) }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">上岗前职业健康检查日期：</span>
          <span class="staff-info-value">{{ dateStrFormatter(msStaffInfoDetailForm.prePeDate) }}</span>
        </div>
        <div class="staff-info-row">
          <span class="staff-info-label">上岗前职业卫生培训日期：</span>
          <span class="staff-info-value">{{ dateStrFormatter(msStaffInfoDetailForm.preTrainingDate) }}</span>
        </div>
      </div>
    </div>
    <!-- 住址、嗜好、家庭病史 -->
    <div style="padding: 16px">
      <div class="staff-info-row">
        <span class="staff-info-label" style="align-self:flex-start;line-height:16px;">住址：</span>
        <span class="staff-info-value">{{ msStaffInfoDetailForm.homeAddr || '--' }}</span>
      </div>
      <div class="staff-info-row">
        <span class="staff-info-label" style="align-self:flex-start;line-height:16px;">嗜好：</span>
        <span class="staff-info-value">{{ msStaffInfoDetailForm.hobby || '--' }}</span>
      </div>
      <div class="staff-info-row">
        <span class="staff-info-label" style="align-self:flex-start;line-height:16px;">家庭病史：</span>
        <span class="staff-info-value">{{ msStaffInfoDetailForm.familyIllness || '--' }}</span>
      </div>
    </div>
    <base-tab :titles="titles" v-model="activeName"></base-tab>
    <div class="zwx-staffInfo-detail-child" v-show="activeName == '1'">
      <!--职业史、既往病史-->
      <staff-occ-history-anamnesis-detail ref="staffOccHistoryAnamnesisDetailRef" :staffId="staffId" :employerUuid="employerUuid" :unitName="unitName"></staff-occ-history-anamnesis-detail>
    </div>
    <div class="zwx-staffInfo-detail-child" v-show="activeName === '2'">
      <!--职业危害告知-->
      <staff-occ-hazard-notification ref="staffOccHazardNotificationRef" :staffId="staffId" :employerUuid="employerUuid"></staff-occ-hazard-notification>
    </div>
    <div v-show="activeName === '3'">
      <!--职业史和职业病危害接触史-->
      <staff-contact-history ref="staffContactHistoryRef" :staffId="staffId" :employerUuid="employerUuid"></staff-contact-history>
    </div>
    <div v-show="activeName === '4'">
      <!--历次职业健康检查结果及处理情况-->
      <staff-previous-results-handling ref="staffPreviousResultsHandlingRef" :staffUid="msStaffInfoDetailForm.uuid" :employerUuid="employerUuid"></staff-previous-results-handling>
    </div>
  </div>
</template>

<script>
import staffOccHistoryAnamnesisDetail from './DetailPageOccupationalHistory.vue' // 职业史、既往病史
import staffOccHazardNotification from './DetailPageOccHazardNotification.vue' // 职业危害告知
import staffContactHistory from './DetailPageContactHistory.vue' // 职业史和职业病危害接触史
import staffPreviousResultsHandling from './DeitalPagePreviousResultsHandling.vue' // 历次职业健康检查结果及处理情况
export default {
  name: 'StaffInductionDetailPage',
  components: {
    staffOccHistoryAnamnesisDetail,
    staffOccHazardNotification,
    staffContactHistory,
    staffPreviousResultsHandling,
  },
  data() {
    return {
      api: this.$store.state.api,
      titles: ['职业史、既往病史', '职业危害告知', '职业史和职业病危害接触史', '历次职业健康检查结果及处理情况'],
      staffId: this.$route.params.rid,
      employerUuid: this.$route.params.employerUuid,
      unitName: '',
      msStaffInfoDetailForm: {
        rid: '',
        uuid: '',
        fkByUnitId: { rid: '' },
        fkByDeptId: { rid: '' },
        fkByStationId: { rid: '' },
        staffName: '', // 姓名
        pinyinCode: '', // 拼音码
        idType: '', // 证件类型(码表)
        idTypeContent: '', //证件类型中文
        cardNo: '', // 证件号
        sex: '', // 性别
        sexContent: '', //性别中文
        phone: '', // 手机号
        outWorker: '', // 是否外委
        jobNumber: '', // 工号
        dutyStatus: '', // 在岗状态（码表）
        termdate: '', // 离职时间
        birthDate: '', // 出生年月
        age: '', // 年龄
        nativePlace: '', // 籍贯
        homeAddr: '', //家庭住址
        ifMarry: '', // 婚否
        culture: '', // 文化程度
        personnelPhoto: '', // 照片
        type: '', // 人员类型;1：接害人员  2：一般人员
        nationality: '', // 国籍
        nationalityContent: '',
        lastUpdateTime: '', // 最后更新时间
        deptName: '', // 部门车间
        stationName: '', // 岗位工种
        hobby: '', //嗜好
        familyIllness: '', //家庭疾病史
        firstWorkDate: '', //初次参加工作时间
        hiredate: '', //到本单位工作时间
        prePeDate: '', //岗前体检日期
        preTrainingDate: '', //岗前培训日期
        ifMarryContent: '', //婚姻状况中文
        cultureContent: '', //文化程度中文
        nativePlaceContent: '', //籍贯
        workplaceName: '', //部门
        jobName: '', //岗位
      },
      stationHazardsList: [], // 岗位危害因素集合
      activeName: '1',
      // 编辑弹框定义变量
      filePath: null,
      annexList: [],
      tdDepartmentInfoList: [],
      tdDeptStationRefList: [],
      types: [
        { code: 1, name: '接害人员' },
        { code: 2, name: '一般人员' },
      ],
      msTdStaffInfoEditData: '',
    }
  },
  created() { },
  watch: {
    activeName(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case '2':
            this.$refs.staffOccHazardNotificationRef.getStaffHazardInformList()
            break
          case '3':
            this.$refs.staffContactHistoryRef.getSstaffContactHistoryList()
            break
          case '4':
            this.$refs.staffPreviousResultsHandlingRef.getStaffPreviousResultsHandlingList()
            break
          default:
        }
      }
    },
  },
  mounted() {
    this.getStaffInfoDetail()
  },
  computed: {},
  methods: {
    /**
     * 是否外委格式化
     */
    outWorkerFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value == true) {
          return '是'
        } else {
          return '否'
        }
      }
      return '--'
    },
    /**
     * 日期格式化
     */
    dateStrFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value) && value.length > 10) {
        return value.substring(0, 10)
      }
      return '--'
    },
    /**
     * 在职状态格式化
     */
    dutyStatusFormatter(value) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        switch (value) {
          case 1:
            return '在职'
          case 2:
            return '离职'
          default:
        }
      }
      return ''
    },
    getStaffInfoDetail() {
      let data = {
        employerUuid: this.employerUuid,
        rid: this.staffId,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getStaffBasicInfo-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msStaffInfoDetailForm = data.staffBasicInfo || {}
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    // getEmployerBasic() {
    //   let data = {
    //     unitUid: this.employerUuid,
    //   }
    //   this.$emit('loading', true)
    //   this.$system.get(
    //     this.api + '/ohsp/getEmployerBasic-1',
    //     data,
    //     true,
    //     true,
    //     data => {
    //       this.$emit('loading', false)
    //       if (data.type == '00') {
    //         this.unitName = data.employerBasic.unitName
    //         this.getStaffInfoDetail()
    //       } else {
    //         this.$emit('loading', false)
    //         this.$system.notify('错误', data.mess, 'error')
    //       }
    //     },
    //     data => {
    //       this.$emit('loading', false)
    //       this.$system.notify('错误', data.mess, 'error')
    //     }
    //   )
    // },
  },
}
</script>

<style lang="less" scoped>
.zwx-staffInfo-detail-child {
  height: calc(100vh - 267px);
}

.staff-basic-info {
  display: flex;
  height: 144px;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid #d5d9e0;
}

.staff-head-img {
  width: 90px;
  height: 112px;
  margin-right: 20px;
}

.staff-info-split-line {
  border-left: 1px solid #d4daf0;
  height: 110px;
  margin: 0 20px;
}

.staff-info-column {
  min-width: 126px;
  // height: 78px;
  display: flex;
  flex-direction: column;
}

.staff-info-row {
  min-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.staff-info-row:last-child {
  margin-bottom: unset;
}

.staff-info-label {
  min-width: 70px;
  font-size: 14px;
  font-weight: 400;
  color: #3a485e;
  line-height: 18px;
}

.staff-info-value {
  min-height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #191919;
  line-height: 18px;
}

.zwx-staff-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 855px !important;
      min-width: 855px !important;
    }
  }

  .staff-edit-panel {
    display: flex;
    padding: 30px 0 30px 20px !important;

    .avatar-upload {
      width: 155px;
      margin-top: 16px;
    }
  }
}

.codeTypeCodeCss {
  /deep/ .zwx-input {
    width: 220px !important;
    height: 28px !important;
  }
}

.name {
  /deep/ .zwx-input .el-input__inner {
    width: 128px !important;
  }

  /deep/ .zwx-input {
    width: 120px !important;
  }
}

.zwx-radio-group {
  min-width: 0 !important;
  font-size: 14px !important;
}
</style>

<style lang="less"></style>
