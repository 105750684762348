var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-occ-hazard-notification" },
    [
      _c(
        "el-table",
        {
          key: "msFormTable",
          staticClass: "zwx-table sortable-table",
          staticStyle: { width: "100%", "margin-top": "-1px" },
          attrs: {
            "row-key": "rid",
            data: _vm.staffOccHazardNotificationList,
            stripe: "",
            border: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "workplaceName",
              label: "部门（车间）",
              "min-width": "40",
              "header-align": "center",
              align: "center",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.workplaceName || "--"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jobName",
              label: "岗位（工种）",
              "min-width": "40",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.jobName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "informDate ",
              label: "告知时间",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$system.formatDate(
                            scope.row.informDate,
                            "YYYY-MM-DD"
                          ) || "--"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "告知附件",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 160
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.informFilePath
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0045F9 !important",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFilePreview(
                                  "职业病危害告知书",
                                  scope.row.informFilePath
                                )
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _c("span", [_vm._v("无")])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }