var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zwx-staff-occ-history-anamnesis-detail" },
    [
      _c(
        "el-form",
        {
          key: "msStaffOccHistoryAnamnesisDetailForm",
          ref: "msStaffOccHistoryAnamnesisDetailForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msStaffOccHistoryAnamnesisDetailForm,
            "label-position": "right"
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top no-bottom",
            attrs: { title: "职业史" }
          }),
          _c(
            "div",
            { staticClass: "staff-occ-history-table-class" },
            [
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data:
                      _vm.msStaffOccHistoryAnamnesisDetailForm
                        .staffOccHistoryTableList,
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "employerName",
                      label: "用人单位",
                      width: "300",
                      "header-align": "center",
                      align: "left"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.employerName || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "workType",
                      label: "岗位（工种）",
                      width: "150",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.workType || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "工作开始时间",
                      width: "120",
                      "header-align": "center",
                      align: "center",
                      prop: "startDate"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$system.formatDate(
                                    scope.row.startDate,
                                    "YYYY-MM-DD"
                                  ) || "--"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "工作结束时间",
                      width: "120",
                      "header-align": "center",
                      align: "center",
                      prop: "endDate"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$system.formatDate(
                                    scope.row.endDate,
                                    "YYYY-MM-DD"
                                  ) || "--"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "exposureHazards",
                      label: "接触危害因素",
                      fixed: "right",
                      "min-width": 600,
                      "header-align": "center",
                      align: "left"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.exposureHazards || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("base-headline", {
            staticClass: "no-top no-bottom",
            attrs: { title: "既往病史" }
          }),
          _c(
            "div",
            { staticClass: "staff-anamnesis-table-class" },
            [
              _c(
                "el-table",
                {
                  staticClass: "zwx-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data:
                      _vm.msStaffOccHistoryAnamnesisDetailForm
                        .staffAnamnesisTableList,
                    border: "",
                    stripe: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "疾病史或手术史",
                      width: "300",
                      "header-align": "center",
                      align: "left",
                      prop: "diseaseName"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.diseaseName || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "就诊日期",
                      width: "120",
                      "header-align": "center",
                      align: "center",
                      prop: "diagnoseDate"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$system.formatDate(
                                    scope.row.diagnoseDate,
                                    "YYYY-MM-DD"
                                  ) || "--"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "就诊单位",
                      "min-width": 160,
                      "header-align": "center",
                      align: "left",
                      prop: "diagnoseUnit",
                      "show-overflow-tooltip": true
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.diagnoseUnit || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "治疗结果",
                      "min-width": 80,
                      "header-align": "center",
                      align: "center",
                      prop: "treatment"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.treatment || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prognosis",
                      label: "转归",
                      fixed: "right",
                      "min-width": 80,
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.prognosis || "--"))
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }