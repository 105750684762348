<template>
  <div class="zwx-staff-occ-history-anamnesis-detail">
    <el-form class="zwx-form edit-form" key="msStaffOccHistoryAnamnesisDetailForm" :model="msStaffOccHistoryAnamnesisDetailForm" ref="msStaffOccHistoryAnamnesisDetailForm" label-position="right">
      <base-headline title="职业史" class="no-top no-bottom"></base-headline>
      <div class="staff-occ-history-table-class">
        <el-table class="zwx-table" style="width: 100%;" :data="msStaffOccHistoryAnamnesisDetailForm.staffOccHistoryTableList" border stripe>
          <el-table-column prop="employerName" label="用人单位" width="300" header-align="center" align="left">
            <template slot-scope="scope">
              <span>{{ scope.row.employerName || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="workType" label="岗位（工种）" width="150" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.workType || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工作开始时间" width="120" header-align="center" align="center" prop="startDate">
            <template slot-scope="scope">
              <span>{{ $system.formatDate(scope.row.startDate, 'YYYY-MM-DD') || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工作结束时间" width="120" header-align="center" align="center" prop="endDate">
            <template slot-scope="scope">
              <span>{{ $system.formatDate(scope.row.endDate, 'YYYY-MM-DD') || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="exposureHazards" label="接触危害因素" fixed="right" :min-width="600" header-align="center" align="left">
            <template slot-scope="scope">
              <span>{{ scope.row.exposureHazards || '--' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <base-headline title="既往病史" class="no-top no-bottom"></base-headline>
      <div class="staff-anamnesis-table-class">
        <el-table class="zwx-table" style="width: 100%;" :data="msStaffOccHistoryAnamnesisDetailForm.staffAnamnesisTableList" border stripe>
          <el-table-column label="疾病史或手术史" width="300" header-align="center" align="left" prop="diseaseName">
            <template slot-scope="scope">
              <span>{{ scope.row.diseaseName || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="就诊日期" width="120" header-align="center" align="center" prop="diagnoseDate">
            <template slot-scope="scope">
              <span>{{ $system.formatDate(scope.row.diagnoseDate, 'YYYY-MM-DD') || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="就诊单位" :min-width="160" header-align="center" align="left" prop="diagnoseUnit" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{ scope.row.diagnoseUnit || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="治疗结果" :min-width="80" header-align="center" align="center" prop="treatment">
            <template slot-scope="scope">
              <span>{{ scope.row.treatment || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="prognosis" label="转归" fixed="right" :min-width="80" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.prognosis || '--' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'staffOccHistoryAnamnesisDetail',
  props: {
    staffId: { type: String, default: '' },
    employerId: { type: String, default: '' },
    unitName: { type: String, default: '' },
  },
  data() {
    return {
      api: this.$store.state.api,
      msStaffOccHistoryAnamnesisDetailForm: {
        staffOccHistoryTableList: [],
        staffAnamnesisTableList: [],
      },
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.getStaffOccHistoryAnamnesisDetail()
  },
  methods: {
    /**
     * 获取职业史和既往病史列表
     * */
    getStaffOccHistoryAnamnesisDetail() {
      let data = {
        employerId: this.employerId,
        rid: this.staffId,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getStaffOccupationalHistoryAndAnamnesis-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.msStaffOccHistoryAnamnesisDetailForm.staffOccHistoryTableList = data.occupationalHistoryList || []
            this.msStaffOccHistoryAnamnesisDetailForm.staffAnamnesisTableList = data.anamnesisList || []
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped></style>
<style>
.staff-anamnesis-table-class .zwx-date-picker {
  width: 100% !important;
}

.staff-anamnesis-table-class .delete-button .zwx-button-text-28 {
  padding-left: 10px !important;
}

.hazard-factors-index .hazard-factors-box-content {
  width: 100% !important;
}

.staff-occ-history-dialog .el-dialog__footer {
  border-top: unset !important;
}

.staff-occ-history-dialog .el-dialog {
  width: calc(100% - 20px) !important;
  min-width: 800px !important;
}

.staff-occ-history-dialog .el-popover__reference-wrapper .zwx-button-text-28 {
  padding-left: 0 !important;
}

.staff-occ-history-dialog .el-popover {
  padding: unset !important;
}

.staff-occ-history-dialog .el-input__icon {
  height: 28px !important;
}
</style>
