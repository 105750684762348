<template>
  <div class="ms-login">
    <div class="login-background" :style="{ backgroundImage: `url(${require('@/assets/images/login/img_bg2.png')}) ` }">
      <div class="login-title">
        <img v-if="config.fileMap[2]&&config.fileMap[2][0]" style="width:100%;" :src="file + config.fileMap[2][0].annexPath" alt="" />
      </div>
      <div class="illustration-box">
        <div class="illustration-box-content">
          <div class="illustration-box-content-leftimg" style=""><img src="@/assets/images/login/img_load.png" /></div>
        </div>
      </div>
      <div class="login-box">
        <div class="login-box-content">
          <el-form key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" class="login-box-content-form">
            <div class="login-box-content-form-title">用户登录</div>
            <div class="login-box-content-form-input">
              <el-form-item prop="userNo">
                <el-input placeholder="请输入登录账号" v-model="mainForm.userNo">
                  <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                </el-input>
              </el-form-item>
            </div>
            <div class="login-box-content-form-input">
              <el-form-item prop="password">
                <el-input placeholder="请输入登录密码" v-model="mainForm.password" :type="view ? 'password' : null" @keyup.enter.native="handler('mainForm')">
                  <i slot="prefix" class="el-input__icon el-icon-s-goods"></i>
                  <i slot="suffix" :class="view ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSee()"></i>
                </el-input>
              </el-form-item>
            </div>
            <div class="login-box-content-form-input">
              <el-form-item :error="errorSlider">
                <!-- <el-input placeholder="验证码" v-model="mainForm.code" @keyup.enter.native="handler('mainForm')" @change="double = 'error' ? (double = true) : ''">
                  <i slot="prefix" class="el-input__icon el-icon-s-claim"></i>
                  <zwx-verify-code slot="append" ref="verify" />
                </el-input> -->
                <slider-check ref="slider" style="width:354px" @change="sliderSuccess"></slider-check>
              </el-form-item>
            </div>
            <div class="login-box-content-form-button">
              <el-button type="primary" @click="handler('mainForm')">登录</el-button>
            </div>
            <div class="login-box-content-form-forgot"></div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="login-footer">
      <div></div>
      <div>
        <span v-if="unitName">Copyright © {{unitName}}</span>
        <span v-if="domainNumber" style="cursor: pointer;margin-left:5px" @click="domainNumberClick">{{ domainNumber }}</span>
        <span v-if="psn">
          <img src="../../assets/images/login/img-national-emblem.png" alt="" style="width:14px;height:14px;margin:0 12px" />
          <span style="cursor: pointer;" @click="psnClick">{{ psn }}</span>
        </span>
      </div>
    </div>
    <!-- 密码修改弹框 -->
    <base-dialog customClass="dialog-custom" ref="modifyPasswordDialog" @determine="modifyPasswordDialogDetermine()" @cancel="modifyPasswordDialogClose()" title="修改密码" :pagination="false">
      <div class="dialog-password-title">
        <i class="el-icon-warning" />
        密码应为8-16位，必须包含大写字母、小写字母和数字
      </div>
      <el-form class="zwx-form" style="padding: 0 !important;" key="modifyForm" ref="modifyForm" :model="modifyForm" :rules="modifyFormRules" label-position="right">
        <div class="edit-form" style="max-width: 392px; padding: 10px 0 5px 20px !important;">
          <el-form-item label-width="82px" label="新的密码：" prop="newPassword">
            <el-input class="zwx-input zwx-input-password" style="width: 290px !important;" v-model="modifyForm.newPassword" placeholder="请输入" :type="newView ? 'password' : null">
              <i slot="suffix" :class="newView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeNew()"></i>
            </el-input>
          </el-form-item>
          <el-form-item label-width="82px" label="确认密码：" prop="confirmPassword">
            <el-input class="zwx-input zwx-input-password" style="width: 290px !important;" v-model="modifyForm.confirmPassword" placeholder="请输入" :type="confirmView ? 'password' : null">
              <i slot="suffix" :class="confirmView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeConfirm()"></i>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      errorSlider: '',
      unitName: this.$store.state.unitName,
      domainNumber: this.$store.state.domainNumber,
      psn: this.$store.state.psn,
      psnNumber: this.$store.state.psnNumber,
      view: true,
      mainForm: {
        userNo: '',
        password: '',
        code: '',
      },
      rules: {
        userNo: [{ required: true, message: '请输入登录账号', trigger: ['change', 'blur'] }],
        password: [{ required: true, message: '请输入登录密码', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '请输入验证码', trigger: ['change', 'blur'] }],
      },
      loading: null,
      redirectParam: this.$route.query.param,
      newView: true,
      confirmView: true,
      modifyForm: {
        newPassword: '',
        confirmPassword: '',
        initPasswordUid: '', //初始密码凭证
      },
      config: {
        fileMap: { 2: {} },
      },
      modifyFormRules: {
        newPassword: [{ required: true, validator: this.$validate.password, trigger: ['change', 'blur'] }],
        confirmPassword: [{ required: true, validator: this.confirmPasswordRules, trigger: ['change', 'blur'] }],
      },
    }
  },
  created() {
    this.getConfig()
  },
  mounted() {
    this.$zwxCookie.delCookie('msTokenId')
    this.$zwxCookie.delCookie('userRole')
    window.localStorage.removeItem('loginState')
  },
  computed: {},
  methods: {
    // 滑块change
    sliderSuccess(val) {
      if (!this.mainForm.userNo) {
        this.errorSlider = '请输入登录账号'
        this.$refs.slider && this.$refs.slider.initSliderCheck()
      } else {
        this.errorSlider = ''
        val.userNo = this.$zwxSm.sm4JsEncrypt(this.mainForm.userNo)
        let data = val
        this.$system.postJson(
          this.api + '/systematic/getLoginSliderToken-0',
          data,
          false,
          true,
          data => {
            if (data.type === '00') {
              this.sliderTokenId = this.$zwxSm.sm2Encrypt123(this.$zwxSm.sm2Decrypt123(data.sliderTokenId))
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$refs.slider && this.$refs.slider.initSliderCheck()
              this.$system.notify('错误', data.mess, 'error')
            }
            this.$emit('loading', false)
          }, this.error)
      }
    },
    //获取我的配置
    getConfig() {
      this.$SimpleCodeTools.getConfig(data => {
        this.config = data
        this.$store.commit('INIT_CONFIG', data)
        let linkTag = document.getElementById('linkico')
        let mstitle = document.getElementById('mstitle')
        if (linkTag) {
          linkTag.href = this.file + data.fileMap[4].annexPath
        }
        if (mstitle) {
          mstitle.innerText = data.systemName
        }
      })
    },
    toRegister() {
      this.$router.push({ name: 'Register' })
    },
    toForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' })
    },
    passSee() {
      this.view = !this.view
    },
    handler(val) {
      this.$refs[val].validate(valid => {
        // 滑块校验
        const flag = this.$refs.slider && !this.$refs.slider.confirmSuccess
        if (flag) {
          this.errorSlider = '请拖动滑块验证'
        } else {
          this.errorSlider = ''
        }
        if (valid && !flag && null == this.loading) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = {
            userNo: this.mainForm.userNo,
            password: this.$zwxSm.sm2Encrypt123(this.mainForm.password),
            // verifyUid: this.$refs.verify.uid,
            // verifyCode: this.mainForm.code,
            sliderTokenId: this.sliderTokenId
          }
          this.$system.post(this.api + '/training/platform/userLoginWithVerifyCode-0', data, false, true, this.success, this.error)
        }
      })
    },
    success(data) {
      if (data.type === '00') {
        this.$system.notify('登录成功', data.mess, 'success')
        this.$zwxCookie.addCookie('msTokenId', data.tokenId)
        window.localStorage.setItem('loginState', 'true')
        let userName = data.userName
        this.$zwxCookie.addCookie('userName', userName)
        if (this.redirectParam) {
          window.localStorage.setItem(this.redirectParam, 'true')
          window.open('', '_self').close()
        } else {
          this.$router.push({ path: `/default` })
        }
      } else if (data.type === '03') {
        // 密码为初始密码 需要修改
        this.$system.notify('提示', data.mess, 'info')
        this.modifyPassword()
        this.modifyForm.initPasswordUid = data.initPasswordUid
      } else {
        this.$zwxCookie.delCookie('msTokenId')
        this.$refs.slider && this.$refs.slider.initSliderCheck()
        this.$system.notify('错误', data.mess, 'error')
      }
      this.loading.close()
      this.loading = null
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
      this.loading = null
    },
    toHome() {
      this.$router.push({ path: '/landingPage?router=1' })
    },
    domainNumberClick() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    psnClick() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + this.psnNumber, '_blank')
    },
    /**
     * 修改密码 相关↓
     */
    passSeeNew() {
      this.newView = !this.newView
    },
    passSeeConfirm() {
      this.confirmView = !this.confirmView
    },
    confirmPasswordRules(rule, value, callback) {
      if (!rule.required && (value === undefined || value === null || value.length === 0)) return callback()
      if (this.modifyForm.newPassword !== this.modifyForm.confirmPassword) return callback(new Error('两次输入的密码不一致'))
      return callback()
    },
    modifyPassword() {
      this.$refs.modifyPasswordDialog.show(true)
    },
    modifyPasswordDialogDetermine() {
      this.$refs['modifyForm'].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = {
            initPasswordUid: this.modifyForm.initPasswordUid,
            newPassword: this.$zwxSm.sm2Encrypt123(this.modifyForm.newPassword),
          }
          this.$system.post(
            this.api + '/systematic/modifyUserInfoInitPassword-0',
            data,
            true,
            true,
            data => {
              if (data.type === '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.$refs.modifyPasswordDialog.show(false)
                this.loading.close()
                this.loading = null
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
                this.loading.close()
                this.loading = null
              }
            },
            () => {
              this.$system.notify('错误', '网络连接失败', 'error')
              this.loading.close()
              this.loading = null
            }
          )
        }
      })
    },
    modifyPasswordDialogClose() {
      this.$refs.modifyPasswordDialog.show(false)
      this.modifyForm.newPassword = ''
      this.modifyForm.confirmPassword = ''
      this.modifyForm.initPasswordUid = ''
    },
  },
}
</script>

<style lang="less" scoped>
.ms-login {
  /deep/.drag {
    height: 50px;
    line-height: 50px;
  }
  /deep/.drag_bg {
    height: 50px;
  }
  /deep/.handler {
    width: 50px;
    height: 50px;
  }
}

.login-background {
  width: 100vw;
  min-width: 1280px;
  height: 100vh;
  // background-position: center;
  // background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  .login-title {
    width: 37%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 7%;
  }
  .illustration-box {
    width: 50%;
    height: 100%;
    // padding-right: 70px;

    &-content {
      height: 100%;
      // height: 590px;
      position: relative;
      // right: 40px;
      // top: calc(50% - 295px);
      text-align: right;
      &-leftimg {
        position: absolute;
        top: 50%;
        right: -50px;
        transform: translateY(-50%);
        img {
          width: 80%;
        }
      }
      &-title {
        margin-right: 130px;
        margin-bottom: 38px;
        cursor: pointer;
      }

      &-feature {
        margin: 30px 40px 70px 0;
        color: #787878;
        font-size: 20px;

        &-service:before {
          content: url('../../assets/images/login/service.svg');
          position: relative;
          top: 4px;
          margin-left: 16px;
          margin-right: 4px;
        }

        &-overall:before {
          content: url('../../assets/images/login/overall.svg');
          position: relative;
          top: 4px;
          margin-left: 16px;
          margin-right: 4px;
        }

        &-training:before {
          content: url('../../assets/images/login/training.svg');
          position: relative;
          top: 4px;
          margin-left: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  .login-box {
    width: 50%;
    height: 100%;

    &-content {
      width: 440px;
      height: 550px;
      padding: 0 43px;
      position: relative;
      top: calc(50% - 295px);
      left: 130px;
      background: #ffffff;
      box-shadow: 2px 7px 18px rgba(204, 219, 240, 1);
      border-radius: 8px;

      &-form {
        width: 100%;
        height: 100%;
        padding: 60px 0 30px 0;

        &-title {
          font-family: 'Harmony Medium';
          font-size: 28px;
          text-align: center;
          color: #1f1f1f;
          letter-spacing: 2px;
          position: relative;
          margin-bottom: 56px;
        }

        &-title:after {
          content: '';
          position: absolute;
          top: 44px;
          right: calc(50% - 24px);
          height: 3px;
          width: 48px;
          background-color: #0088ff;
        }

        &-input {
          margin-bottom: 30px;
        }

        &-forgot {
          text-align: right;
          color: #6d87b0;
          font-size: 14px;
          margin-top: 26px;

          span {
            cursor: pointer;
            padding-left: 20px;
          }

          span:hover {
            color: #696969;
          }

          span:active {
            color: #0059ff;
          }
        }

        &-button {
          width: 100%;

          .el-button {
            font-family: 'Harmony Light';
            width: 100%;
            height: 60px !important;
            background: #0088ff !important;
            border-radius: unset;
            letter-spacing: 2px;
            font-size: 20px;
          }

          .el-button:hover {
            background: #33a0ff !important;
          }

          .el-button:active {
            background: #0059ff !important;
          }
        }
      }

      /deep/ .el-input {
        font-size: 16px;
        color: #b5bac2;
      }

      /deep/ .el-input__inner {
        border-radius: 3px !important;
        border: unset !important;
        background: #f2f4f9;
      }

      /deep/ .el-input__inner,
      .el-input__icon {
        height: 50px !important;
        line-height: 50px !important;
      }

      /deep/ .el-icon-view {
        cursor: pointer;
      }

      /deep/ .el-input-group__append,
      .el-input-group__prepend {
        padding: 0;
        border: none !important;
      }

      /deep/ .ms-verify-code,
      .verify-img {
        border-radius: 0 3px 3px 0 !important;
      }

      /deep/ .el-form-item {
        margin-bottom: 12px;
      }
    }
  }
}

.login-footer {
  position: fixed;
  bottom: 38px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #6d87b0;
  font-family: 'Harmony Light';

  div:first-child {
    font-size: 16px;
    margin-bottom: 12px;
  }
}
</style>
