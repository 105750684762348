var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-induction-detail-page" },
    [
      _c("base-headline", {
        staticClass: "headline no-top no-bottom",
        attrs: { title: "员工基本档案" }
      }),
      _c("el-divider", { staticClass: "base-divider" }),
      _c("div", { staticClass: "staff-basic-info" }, [
        _c("div", { staticClass: "staff-head-img" }, [
          _vm.$zwxBase.verifyIsNotBlank(
            _vm.msStaffInfoDetailForm.personnelPhoto
          )
            ? _c("img", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  src:
                    _vm.$store.state.file +
                    _vm.msStaffInfoDetailForm.personnelPhoto
                }
              })
            : _vm._e(),
          _vm.$zwxBase.verifyIsBlank(_vm.msStaffInfoDetailForm.personnelPhoto)
            ? _c("div", { staticClass: "ic-avatar-empty" })
            : _vm._e()
        ]),
        _c("div", { staticClass: "staff-info-column" }, [
          _c("div", { staticClass: "staff-info-row" }, [
            _c(
              "div",
              {
                staticClass: "staff-info-label",
                staticStyle: { "min-width": "42px !important" }
              },
              [_vm._v("姓名：")]
            ),
            _c("div", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.staffName))
            ]),
            _vm.msStaffInfoDetailForm.sexContent === "男"
              ? _c("i", {
                  staticClass: "colorful chis-icon-colorful-male",
                  staticStyle: { "margin-left": "4px" }
                })
              : _vm._e(),
            _vm.msStaffInfoDetailForm.sexContent === "女"
              ? _c("i", {
                  staticClass: "colorful chis-icon-colorful-female",
                  staticStyle: { "margin-left": "4px" }
                })
              : _vm._e(),
            _vm.msStaffInfoDetailForm.type === 1
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "34px",
                      height: "18px",
                      background: "#e75d55",
                      "font-size": "12px",
                      "font-weight": "500",
                      color: "#ffffff",
                      "line-height": "18px",
                      "letter-spacing": "0.6px",
                      "text-align": "center",
                      "border-radius": "3px",
                      "margin-left": "15px"
                    }
                  },
                  [_vm._v("接害")]
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("出生日期：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.dateStrFormatter(_vm.msStaffInfoDetailForm.birthDate)
                  ) +
                  " "
              ),
              _vm.$zwxBase.verifyIsNotBlank(_vm.msStaffInfoDetailForm.age)
                ? _c("span", [
                    _vm._v("(" + _vm._s(_vm.msStaffInfoDetailForm.age) + "岁)")
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("手机号码：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.phone || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c(
              "span",
              {
                staticClass: "staff-info-label",
                staticStyle: { "min-width": "42px !important" }
              },
              [_vm._v("籍贯：")]
            ),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(_vm.msStaffInfoDetailForm.nativePlaceContent || "--")
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "staff-info-split-line" }),
        _c("div", { staticClass: "staff-info-column" }, [
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("证件类型：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.idTypeContent || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("证件号码：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.cardNo || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("婚姻状态：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.ifMarryContent || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("文化程度：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.cultureContent || "--"))
            ])
          ])
        ]),
        _c("div", { staticClass: "staff-info-split-line" }),
        _c("div", { staticClass: "staff-info-column" }, [
          _c("div", { staticClass: "staff-info-row" }, [
            _c(
              "span",
              {
                staticClass: "staff-info-label",
                staticStyle: { "min-width": "42px !important" }
              },
              [_vm._v("工号：")]
            ),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.jobNumber || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("是否外委：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(
                  _vm.outWorkerFormatter(_vm.msStaffInfoDetailForm.outWorker)
                )
              )
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c(
              "span",
              {
                staticClass: "staff-info-label",
                staticStyle: { "min-width": "86px" }
              },
              [_vm._v("岗位 (工种) ：")]
            ),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.jobName || "--"))
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c(
              "span",
              {
                staticClass: "staff-info-label",
                staticStyle: { "min-width": "86px" }
              },
              [_vm._v("部门 (车间) ：")]
            ),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(_vm._s(_vm.msStaffInfoDetailForm.workplaceName || "--"))
            ])
          ])
        ]),
        _c("div", { staticClass: "staff-info-split-line" }),
        _c("div", { staticClass: "staff-info-column" }, [
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("初次参加工作时间：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(
                  _vm.dateStrFormatter(_vm.msStaffInfoDetailForm.firstWorkDate)
                )
              )
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("到本单位工作时间：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(_vm.dateStrFormatter(_vm.msStaffInfoDetailForm.hiredate))
              )
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("上岗前职业健康检查日期：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(
                  _vm.dateStrFormatter(_vm.msStaffInfoDetailForm.prePeDate)
                )
              )
            ])
          ]),
          _c("div", { staticClass: "staff-info-row" }, [
            _c("span", { staticClass: "staff-info-label" }, [
              _vm._v("上岗前职业卫生培训日期：")
            ]),
            _c("span", { staticClass: "staff-info-value" }, [
              _vm._v(
                _vm._s(
                  _vm.dateStrFormatter(
                    _vm.msStaffInfoDetailForm.preTrainingDate
                  )
                )
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticStyle: { padding: "16px" } }, [
        _c("div", { staticClass: "staff-info-row" }, [
          _c(
            "span",
            {
              staticClass: "staff-info-label",
              staticStyle: { "align-self": "flex-start", "line-height": "16px" }
            },
            [_vm._v("住址：")]
          ),
          _c("span", { staticClass: "staff-info-value" }, [
            _vm._v(_vm._s(_vm.msStaffInfoDetailForm.homeAddr || "--"))
          ])
        ]),
        _c("div", { staticClass: "staff-info-row" }, [
          _c(
            "span",
            {
              staticClass: "staff-info-label",
              staticStyle: { "align-self": "flex-start", "line-height": "16px" }
            },
            [_vm._v("嗜好：")]
          ),
          _c("span", { staticClass: "staff-info-value" }, [
            _vm._v(_vm._s(_vm.msStaffInfoDetailForm.hobby || "--"))
          ])
        ]),
        _c("div", { staticClass: "staff-info-row" }, [
          _c(
            "span",
            {
              staticClass: "staff-info-label",
              staticStyle: { "align-self": "flex-start", "line-height": "16px" }
            },
            [_vm._v("家庭病史：")]
          ),
          _c("span", { staticClass: "staff-info-value" }, [
            _vm._v(_vm._s(_vm.msStaffInfoDetailForm.familyIllness || "--"))
          ])
        ])
      ]),
      _c("base-tab", {
        attrs: { titles: _vm.titles },
        model: {
          value: _vm.activeName,
          callback: function($$v) {
            _vm.activeName = $$v
          },
          expression: "activeName"
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName == "1",
              expression: "activeName == '1'"
            }
          ],
          staticClass: "zwx-staffInfo-detail-child"
        },
        [
          _c("staff-occ-history-anamnesis-detail", {
            ref: "staffOccHistoryAnamnesisDetailRef",
            attrs: {
              staffId: _vm.staffId,
              employerUuid: _vm.employerUuid,
              unitName: _vm.unitName
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "2",
              expression: "activeName === '2'"
            }
          ],
          staticClass: "zwx-staffInfo-detail-child"
        },
        [
          _c("staff-occ-hazard-notification", {
            ref: "staffOccHazardNotificationRef",
            attrs: { staffId: _vm.staffId, employerUuid: _vm.employerUuid }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "3",
              expression: "activeName === '3'"
            }
          ]
        },
        [
          _c("staff-contact-history", {
            ref: "staffContactHistoryRef",
            attrs: { staffId: _vm.staffId, employerUuid: _vm.employerUuid }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "4",
              expression: "activeName === '4'"
            }
          ]
        },
        [
          _c("staff-previous-results-handling", {
            ref: "staffPreviousResultsHandlingRef",
            attrs: {
              staffUid: _vm.msStaffInfoDetailForm.uuid,
              employerUuid: _vm.employerUuid
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }