var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-previous-results-handling" },
    [
      _c(
        "el-table",
        {
          key: "msFormTable",
          staticClass: "zwx-table sortable-table",
          staticStyle: { width: "100%", "margin-top": "-1px" },
          attrs: {
            "row-key": "rid",
            data: _vm.staffPreviousResultsHandlingList,
            stripe: "",
            border: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "peDate ",
              label: "体检日期",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$system.formatDate(
                            scope.row.peDate,
                            "YYYY-MM-DD"
                          ) || "--"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "peCode",
              label: "体检编号",
              "min-width": "80",
              "header-align": "center",
              align: "center",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.peCode || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "organName",
              label: "体检机构",
              "min-width": "80",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.organName || "--"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "peType ",
              label: "职业病体检类型",
              width: "150",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.peType == 1
                            ? "职业健康体检"
                            : scope.row.peType == 2
                            ? "放射人员健康检查"
                            : scope.row.peType == 3
                            ? "福利体检"
                            : "--"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mainInspectionAdvice ",
              label: "主检建议",
              "min-width": "120",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.mainInspectionAdvice || "--"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "体检结果告知",
              "header-align": "center",
              align: "center",
              width: 160
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.informFilePath
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0045F9 !important",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFilePreview(
                                  "体检结果告知书",
                                  scope.row.informFilePath
                                )
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _c("span", [_vm._v("无")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "handlingWayStr ",
              label: "异常结果处置",
              "min-width": "120",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.handlingWayStr || "--"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "80",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.toPeDetailPage(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }