<template>
  <div class="staff-previous-results-handling">
    <el-table class="zwx-table sortable-table" style="width: 100%;margin-top:-1px" row-key="rid" key="msFormTable" :data="staffPreviousResultsHandlingList" stripe border tooltip-effect="light">
      <el-table-column prop="peDate " label="体检日期" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $system.formatDate(scope.row.peDate, 'YYYY-MM-DD') || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="peCode" label="体检编号" min-width="80" header-align="center" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.peCode || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="organName" label="体检机构" min-width="80" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.organName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="peType " label="职业病体检类型" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.peType == 1 ? '职业健康体检' : scope.row.peType == 2 ? '放射人员健康检查' : scope.row.peType == 3 ? '福利体检' : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mainInspectionAdvice " label="主检建议" min-width="120" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.mainInspectionAdvice || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="体检结果告知" header-align="center" align="center" :width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.informFilePath" style="color: #0045F9 !important;cursor: pointer;" @click="openFilePreview('体检结果告知书', scope.row.informFilePath)">查看</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="handlingWayStr " label="异常结果处置" min-width="120" header-align="left" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.handlingWayStr || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" min-width="80" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="toPeDetailPage(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'staffPreviousResultsHandling',
  props: {
    staffUid: { type: String, default: '' },
    employerUuid: { type: String, default: '' },
  },
  data() {
    return {
      api: this.$store.state.api,
      staffPreviousResultsHandlingList: [],
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /**
     * 获取历次职业健康检查结果及处理情况
     * */
    getStaffPreviousResultsHandlingList() {
      let data = {
        employerUuid: this.employerUuid,
        staffUid: this.staffUid,
      }
      this.$system.get(
        this.api + '/oh/health/surveillance/getStaffPeRecordTreatmentList-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.staffPreviousResultsHandlingList = data.staffPeRecordList || []
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 单个文件预览
     */
    openFilePreview: function (fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 查看体检详情
     */
    toPeDetailPage(row) {
      this.$router.push({
        name: 'PeRecordDetail',
        params: {employerUuid: this.employerUuid, rid: row.rid, type: 'outJump'},
      })
    }
  },
}
</script>

<style lang="less" scoped></style>
<style></style>
