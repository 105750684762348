<template>
  <div class="staff-contact-history">
    <el-table class="zwx-table sortable-table" style="width: 100%;margin-top:-1px" row-key="rid" key="msFormTable" :data="staffContactHistoryList" stripe border tooltip-effect="light">
      <el-table-column label="起止时间" width="240" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.startEndTime || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="oldDeptName" label="部门（车间）" width="150" header-align="center" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.oldDeptName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="oldStationName" label="岗位（工种）" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.oldStationName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="involvedHazards " label="接触职业病危害因素" min-width="120" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.involvedHazards || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="个人防护用品配备" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.equippedPpe || '--' }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'staffContactHistory',
  props: {
    staffId: { type: String, default: '' },
    employerId: { type: String, default: '' },
  },
  data() {
    return {
      api: this.$store.state.api,
      staffContactHistoryList: [],
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /**
     * 获取员工职业史和职业病危害接触史
     * */
    getSstaffContactHistoryList() {
      let data = {
        employerId: this.employerId,
        rid: this.staffId,
      }
      this.$system.get(
        this.api + '/zky/employer/getStaffPersonnelTransferRecordList-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.staffContactHistoryList = data.personnelTransferRecordList || []
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped></style>
<style>
.zwx-staff-detection-report-detail .zwx-table th {
  border-top: unset !important;
}
</style>
