<template>
  <div class="staff-occ-hazard-notification">
    <el-table class="zwx-table sortable-table" style="width: 100%;margin-top:-1px" row-key="rid" key="msFormTable" :data="staffOccHazardNotificationList" stripe border tooltip-effect="light">
      <el-table-column prop="workplaceName" label="部门（车间）" min-width="40" header-align="center" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.workplaceName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jobName" label="岗位（工种）" min-width="40" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.jobName || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="informDate " label="告知时间" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ $system.formatDate(scope.row.informDate, 'YYYY-MM-DD') || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="告知附件" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.informFilePath" style="color: #0045F9 !important;cursor: pointer;" @click="openFilePreview('职业病危害告知书', scope.row.informFilePath)">查看</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'staffOccHazardNotification',
  props: {
    staffId: { type: String, default: '' },
    employerId: { type: String, default: '' },
  },
  data() {
    return {
      api: this.$store.state.api,
      staffOccHazardNotificationList: [],
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    getStaffHazardInformList() {
      let data = {
        employerId: this.employerId,
        rid: this.staffId,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getStaffHazardInformList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.staffOccHazardNotificationList = data.informDetailList || []
            this.staffOccHazardNotificationList.map(item => {
              item.fileIconShow = this.dealfilePrefix(item.fileName)
              return item
            })
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped></style>
<style></style>
